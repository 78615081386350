<template>
    <div
        class="item_wrapper"
        :class="{
            diff_width: isWidthDiff,
            no_diff_width: !isWidthDiff,
        }"
    >
        <p class="text_wrapper">{{ text }}</p>
        <BIconX class="text_wrapper icon-wrapper" @click="removeItem"></BIconX>
    </div>
</template>

<script>
import { BIconX } from 'bootstrap-vue';
export default {
    components: {
        BIconX,
    },
    props: {
        text: String,
        id: Number,
        isWidthDiff: Boolean,
    },
    methods: {
        removeItem() {
            this.$emit('removeSelectedItem', Number(this.id));
        },
    },
};
</script>

<style>
.item_wrapper {
    height: 100%;
    background: gainsboro;
    border-right: 1px solid black;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-overflow: ellipsis;
    font-weight: 600;
}
.diff_width {
    width: 100%;
}
.no_diff_width {
    width: 15%;
}
.text_wrapper {
    font-size: 0.75em !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-self: center;
}
.icon-wrapper {
    width: 20px;
    height: 20px;
    cursor: pointer;
}
@media only screen and (max-width: 1024px) {
    .item_wrapper {
        height: 100%;
        background: gainsboro;
        border-right: 1px solid black;
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-overflow: ellipsis;
        font-weight: 600;
        min-width: 120px;
        overflow-x: scroll;
    }
}
</style>
